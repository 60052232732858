// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogg-index-js": () => import("./../../../src/pages/blogg/index.js" /* webpackChunkName: "component---src-pages-blogg-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konsulter-index-js": () => import("./../../../src/pages/konsulter/index.js" /* webpackChunkName: "component---src-pages-konsulter-index-js" */),
  "component---src-pages-kontakta-oss-index-js": () => import("./../../../src/pages/kontakta-oss/index.js" /* webpackChunkName: "component---src-pages-kontakta-oss-index-js" */),
  "component---src-pages-lediga-jobb-index-js": () => import("./../../../src/pages/lediga-jobb/index.js" /* webpackChunkName: "component---src-pages-lediga-jobb-index-js" */),
  "component---src-pages-tjanster-index-js": () => import("./../../../src/pages/tjänster/index.js" /* webpackChunkName: "component---src-pages-tjanster-index-js" */),
  "component---src-templates-blogg-js": () => import("./../../../src/templates/blogg.js" /* webpackChunkName: "component---src-templates-blogg-js" */),
  "component---src-templates-bloggs-js": () => import("./../../../src/templates/bloggs.js" /* webpackChunkName: "component---src-templates-bloggs-js" */),
  "component---src-templates-konsult-js": () => import("./../../../src/templates/konsult.js" /* webpackChunkName: "component---src-templates-konsult-js" */),
  "component---src-templates-lediga-jobb-js": () => import("./../../../src/templates/lediga-jobb.js" /* webpackChunkName: "component---src-templates-lediga-jobb-js" */),
  "component---src-templates-tjanst-js": () => import("./../../../src/templates/tjänst.js" /* webpackChunkName: "component---src-templates-tjanst-js" */)
}

